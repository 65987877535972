// import { LocaleSelector } from '@/components/common/locale-selector';
import Link from 'next/link';
import AppIcon from '@/components/icons/app-icon';
import DownloadAppIcon from '@/components/icons/download-app-icon';

export function Banner() {
  return (
    <div className='h-20 border-b bg-background w-full'>
      <div className='container h-full flex items-center justify-center w-full'>
        {/* <div className='hidden sm:block' /> */}
        <div className='flex items-center gap-x-6'>
          <AppIcon className='hidden sm:block w-14 h-14' />
          <div className='hidden sm:block'>
            <h3 className='font-bold text-lg'>See and Apply to More Jobs</h3>
            <p className=''>Get the full experience in the app</p>
          </div>
          <Link
            href='https://apps.apple.com/us/app/jobreel/id6473167014'
            target='_blank'
          >
            <DownloadAppIcon className='w-full max-w-[150px]' />
          </Link>
        </div>
        {/* <LocaleSelector /> */}
      </div>
    </div>
  );
}

export default Banner;
